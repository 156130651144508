var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import renderTemplate from './_tabs.hbs';
import './tabs.scss';
import { getPropertiesFromClassName, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { TABS_ARIA_SELECTED, TABS_BASE_CLASSNAME, TABS_BUTTON_CLASSNAME, TABS_BUTTON_ICON_CLASSNAME, TABS_COLOR_TYPES, TABS_CONTENTS_CLASSNAME, TABS_CONTENT_ACTIVE_CLASSNAME, TABS_CONTENT_ID_PREFIX, TABS_CONTROL_CLASSNAME, TABS_CONTAINER_CLASSNAME, TABS_LABEL_CLASSNAME, TABS_TAB_ACTIVE_CLASSNAME, TABS_TAB_CLASSNAME, TABS_TRACK_AUTO_WIDTH_CLASSNAME, TABS_TRACK_CLASSNAME, TABS_TAB_INDEX_ATTR, TABS_ARIA_LABELLED_BY, TABS_ARIA_LABEL, TabsNavigationKeys, } from './Constants';
import { TabsParamService } from './TabsParamService';
import { getSystemIconName } from '@vfde-brix/ws10/system-icon';
import { BELT_CONTAINER_BASE_CLASSNAME } from '../belt-container/constants';
/**
 * Tabs
 */
var Tabs = /** @class */ (function (_super) {
    __extends(Tabs, _super);
    function Tabs() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.tabsParamService = new TabsParamService();
        return _this;
    }
    /**
     * afterInit (called once after init)
     */
    Tabs.prototype.afterInit = function () {
        this.handleDeepLink();
    };
    /**
     * Set default props
     */
    Tabs.prototype.getDefaultProperties = function (newProperties) {
        if (!newProperties.listTabs.some(function (item) { return item.optActive; })) {
            var firstTab = newProperties.listTabs[0];
            firstTab && (firstTab.optActive = true);
        }
        return newProperties;
    };
    /**
     * All rendering gets done with this function.
     */
    Tabs.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    Tabs.prototype.initEvents = function () {
        var _this = this;
        var buttonElements = __spreadArray([], __read(this.containerElement.getElementsByClassName(TABS_BUTTON_CLASSNAME)), false);
        var numTabs = buttonElements.length;
        buttonElements.forEach(function (element, index) {
            element.addEventListener('click', function () {
                var tabId = element.parentElement.id;
                var tabsElement = (_this.containerElement.getElementsByClassName(TABS_BASE_CLASSNAME)[0]);
                _this.handleActivateTab(tabId);
                _this.scrollIntoView();
                if (tabsElement.dataset.deeplink === 'true') {
                    _this.tabsParamService.setActiveTabUrlParam(tabId);
                }
            });
            element.addEventListener('keydown', function (event) {
                if (event.key === TabsNavigationKeys.ArrowLeft || event.key === TabsNavigationKeys.ArrowRight) {
                    event.preventDefault();
                    var focusedIndex = index;
                    if (event.key === TabsNavigationKeys.ArrowLeft) {
                        do {
                            focusedIndex = (focusedIndex - 1 + numTabs) % numTabs;
                        } while (buttonElements[focusedIndex].disabled && focusedIndex !== index);
                    }
                    else {
                        do {
                            focusedIndex = (focusedIndex + 1) % numTabs;
                        } while (buttonElements[focusedIndex].disabled && focusedIndex !== index);
                    }
                    if (!buttonElements[focusedIndex].disabled) {
                        buttonElements[focusedIndex].focus();
                        _this.scrollIntoView(buttonElements[focusedIndex].parentElement);
                    }
                }
            });
        });
        window.addEventListener('resize', function () {
            buttonElements.forEach(function (element) {
                if (element.parentElement.classList.contains(TABS_TAB_ACTIVE_CLASSNAME)) {
                    _this.scrollIntoView(element.parentElement);
                }
            });
        });
    };
    /**
     * If the html comes from the CMS, the component needs a way to know what the properties are,
     * so this gets the properties from the DOM elements
     */
    Tabs.prototype.readDom = function (tabsBusinessLogic) {
        var e_1, _a;
        var _b, _c;
        var tabsElement = (this.containerElement.getElementsByClassName(TABS_BASE_CLASSNAME)[0]);
        var optDeepLink = tabsElement.dataset.deeplink === 'true' || undefined;
        var optColor = getPropertiesFromClassName(tabsElement.className, {
            optColor: TABS_COLOR_TYPES,
        }).optColor;
        var optAutoWidth = ((_b = this.containerElement
            .getElementsByClassName(TABS_TRACK_CLASSNAME)[0]) === null || _b === void 0 ? void 0 : _b.classList.contains(TABS_TRACK_AUTO_WIDTH_CLASSNAME)) || undefined;
        var stdAriaLabelledBy = this.containerElement
            .getElementsByClassName(TABS_TRACK_CLASSNAME)[0]
            .getAttribute(TABS_ARIA_LABELLED_BY) || undefined;
        var stdAriaLabel = this.containerElement
            .getElementsByClassName(TABS_TRACK_CLASSNAME)[0]
            .getAttribute(TABS_ARIA_LABEL) || undefined;
        var optBeltContainer = ((_c = this.containerElement
            .getElementsByClassName(TABS_CONTAINER_CLASSNAME)[0]) === null || _c === void 0 ? void 0 : _c.classList.contains(BELT_CONTAINER_BASE_CLASSNAME)) || undefined;
        var tabsElements = __spreadArray([], __read(this.containerElement.getElementsByClassName(TABS_TAB_CLASSNAME)), false);
        var contentsElement = this.containerElement.getElementsByClassName(TABS_CONTENTS_CLASSNAME)[0];
        var listTabs = [];
        try {
            // Loop through each tab in the DOM
            for (var tabsElements_1 = __values(tabsElements), tabsElements_1_1 = tabsElements_1.next(); !tabsElements_1_1.done; tabsElements_1_1 = tabsElements_1.next()) {
                var tabElement = tabsElements_1_1.value;
                var buttonElement = tabElement.getElementsByClassName(TABS_BUTTON_CLASSNAME)[0];
                var stdId = tabElement.id;
                var optActive = tabElement.classList.contains(TABS_TAB_ACTIVE_CLASSNAME);
                var stdIcon = void 0;
                if (buttonElement.classList.contains(TABS_BUTTON_ICON_CLASSNAME)) {
                    stdIcon = getSystemIconName(buttonElement);
                }
                var optDisabled = buttonElement.disabled || undefined;
                var stdLabel = tabElement
                    .getElementsByClassName(TABS_LABEL_CLASSNAME)[0]
                    .innerHTML.trim();
                var containerAnyComponent = contentsElement
                    .querySelector("[data-for=\"".concat(stdId, "\"]"))
                    .innerHTML.replace(/\s+/g, ' ')
                    .trim();
                listTabs.push(removeUndefinedFieldsFromObject({
                    stdId: stdId,
                    stdIcon: stdIcon,
                    stdLabel: stdLabel,
                    containerAnyComponent: containerAnyComponent,
                    optActive: optActive,
                    optDisabled: optDisabled,
                }));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (tabsElements_1_1 && !tabsElements_1_1.done && (_a = tabsElements_1.return)) _a.call(tabsElements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return removeUndefinedFieldsFromObject({
            listTabs: listTabs,
            optAutoWidth: optAutoWidth,
            optBeltContainer: optBeltContainer,
            optColor: optColor,
            optDeepLink: optDeepLink,
            stdAriaLabelledBy: stdAriaLabelledBy,
            stdAriaLabel: stdAriaLabel,
            business: tabsBusinessLogic,
        });
    };
    /**
     * Event to handle active tab selection
     */
    Tabs.prototype.handleDeepLink = function () {
        var _this = this;
        var tabsElement = this.containerElement.getElementsByClassName(TABS_BASE_CLASSNAME)[0];
        if (tabsElement.dataset.deeplink !== 'true') {
            return;
        }
        var activeTabId = this.tabsParamService.getActiveTabUrlParam();
        var elements = __spreadArray([], __read(this.containerElement.getElementsByClassName(TABS_TAB_CLASSNAME)), false);
        elements.forEach(function (element) {
            if (element.id === activeTabId && !element.firstElementChild.disabled) {
                _this.handleActivateTab(activeTabId);
                _this.scrollIntoView();
                return;
            }
        });
    };
    /**
     * Event to handle active tab selection
     */
    Tabs.prototype.handleActivateTab = function (tabId) {
        var activeTabElement = this.containerElement.getElementsByClassName(TABS_TAB_ACTIVE_CLASSNAME)[0];
        var activeContentElement = this.containerElement.getElementsByClassName(TABS_CONTENT_ACTIVE_CLASSNAME)[0];
        var activeTabProperty = this.properties.listTabs.find(function (tab) { return tab.stdId === activeTabElement.id; });
        var toActivateTabElement = document.getElementById(tabId);
        var toActiveteContentElement = document.getElementById(TABS_CONTENT_ID_PREFIX + tabId);
        var toActivateTabProperty = this.properties.listTabs.find(function (tab) { return tab.stdId === tabId; });
        activeTabElement.classList.remove(TABS_TAB_ACTIVE_CLASSNAME);
        activeTabElement.firstElementChild.setAttribute(TABS_ARIA_SELECTED, 'false');
        activeTabElement.firstElementChild.setAttribute(TABS_TAB_INDEX_ATTR, '-1');
        activeContentElement.classList.remove(TABS_CONTENT_ACTIVE_CLASSNAME);
        activeTabProperty.optActive = false;
        toActivateTabElement.classList.add(TABS_TAB_ACTIVE_CLASSNAME);
        toActivateTabElement.firstElementChild.setAttribute(TABS_ARIA_SELECTED, 'true');
        toActivateTabElement.firstElementChild.removeAttribute(TABS_TAB_INDEX_ATTR);
        toActiveteContentElement.classList.add(TABS_CONTENT_ACTIVE_CLASSNAME);
        toActivateTabProperty.optActive = true;
    };
    /**
     * scrolls active element into view
     */
    Tabs.prototype.scrollIntoView = function (focusedElem) {
        var activeElement = focusedElem || (this.containerElement.getElementsByClassName(TABS_TAB_ACTIVE_CLASSNAME)[0]);
        var controlElement = (this.containerElement.getElementsByClassName(TABS_CONTROL_CLASSNAME)[0]);
        var centerPosition = activeElement.offsetLeft - ((controlElement.offsetWidth / 2) - (activeElement.offsetWidth / 2));
        controlElement.scrollLeft = centerPosition;
    };
    return Tabs;
}(Pattern));
export { Tabs };
/**
 * This function returns an instance of Tabs
 */
export var createTabs = function (containerElement, businessLogicOrProperties) {
    var tabs = new Tabs(containerElement, businessLogicOrProperties);
    tabs.init();
    return tabs;
};
